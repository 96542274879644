<template>

    <div>
        <page-title :heading="$t('accounting.lang_buchhungsaetze')" :subheading="$t('accounting.lang_buchhungsaetze')" :icon=icon></page-title>
        <div class="app-main__inner">
                <CashBookPostingRecords></CashBookPostingRecords>
            </div>

    </div>


</template>

<script>
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import CashBookPostingRecords from "../../components/accounting/CashBookPostingRecords.vue";

    export default {
        components: {
            PageTitle,
            CashBookPostingRecords
        },

        data: () => ({
            icon: 'pe-7s-plugin icon-gradient bg-tempting-azure',
        }),
    }
</script>